import React from "react";
import HubspotForm from "react-hubspot-form";

function ProspectForm() {
	return (
		<div className="form">
			<>
				 <p className="form__description">Awesome! Let’s get some more info...</p>
				<HubspotForm
					portalId="544161"
					formId="a10ad2e6-c3fb-4ac3-9528-6ea5d9eb6140"
					region="na1"
					css=""
					submitButtonClass="btn btn--black-green"
				/>
			</>
		</div>
	);
}

export default ProspectForm;

import React, { FunctionComponent } from "react";
import HubspotForm from "react-hubspot-form";

const CareersForm: FunctionComponent = () => {
	return (
		<>
			 <p className="form__description">Don't see the job you want on the careers page? (Hint, it's in the footer). Send us a message here and the leprechauns will make a new job for you.</p>
			<HubspotForm
				portalId="544161"
				formId="e252156b-657f-4d83-bddd-156f6e2b4988"
				region="na1"
				css=""
				submitButtonClass="btn btn--black-green"
			/>
		</>
	);
};

export default CareersForm;

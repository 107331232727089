import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import COLORS from "../../utils/colors";

const useStyles = makeStyles({
	root: {
		padding: "16px 8px",
		"&:hover": {
			backgroundColor: "transparent",
		},
	},
	icon: {
		borderRadius: "50%",
		width: 20,
		height: 20,
		border: `2px solid ${COLORS.ALMOST_BLACK}`,
	},
	checkedIcon: {
		width: 36,
		height: 36,
		backgroundImage: `url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 43 44'%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath d='M36.5 2.923L18.776 13.497l3.61 20.422L36.5 2.923z' fill='url(%23paint0_linear)'/%3E%3Cpath d='M18.78 13.498L6.5 6.438l15.887 27.484-3.607-20.424z' fill='url(%23paint1_linear)'/%3E%3Cpath d='M18.779 13.496l17.72-10.574L6.5 6.435l12.279 7.061z' fill='url(%23paint2_linear)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='31.119' y1='17.495' x2='-2.164' y2='-4.702' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='.01' stop-color='%230C2F03'/%3E%3Cstop offset='.191' stop-color='%2323760D'/%3E%3Cstop offset='.521' stop-color='%2398D956'/%3E%3Cstop offset='.934' stop-color='%23DBFBBC'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear' x1='20.939' y1='19.927' x2='-1.765' y2='15.34' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2323760D'/%3E%3Cstop offset='.521' stop-color='%2398D956'/%3E%3Cstop offset='1' stop-color='%23DBFBBC'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint2_linear' x1='31.67' y1='15.08' x2='26.723' y2='2.497' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2323760D'/%3E%3Cstop offset='.521' stop-color='%2398D956'/%3E%3Cstop offset='1' stop-color='%23DBFBBC'/%3E%3C/linearGradient%3E%3Cfilter id='filter0_d' x='.5' y='.922' width='42' height='43' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeMorphology radius='4' in='SourceAlpha' result='effect1_dropShadow'/%3E%3CfeOffset dy='4'/%3E%3CfeGaussianBlur stdDeviation='5'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.38 0'/%3E%3CfeBlend in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E")`,
		transform: "translate3d(-10px, -5px, 0px)",
	},
});

function StyledRadio(props) {
	const classes = useStyles();

	return (
		<Radio
			className={classes.root}
			disableRipple
			color="default"
			checkedIcon={
				<div className={classes.icon}>
					<div className={classes.checkedIcon}></div>
				</div>
			}
			icon={<span className={classes.icon} />}
			{...props}
		/>
	);
}

export default StyledRadio;

/**
 * Changes to hex codes here will likely need to also be made in `src/styles/abstracts/variables/_colors.scss`
 */
const COLORS = {
	IT_GREEN: "#85D038",
	ATOMIC_LIME: "#A8EC63",
	GREEN_TEXT: "#5DA414",
	ORANGE_U_GLAD: "#FD8D28",
	ORANGE_TEXT: "#E17412",
	ALMOST_BLACK: "#282828",
	WHITE: "#FFFFFF",
	NOT_QUITE_WHITE: "#FCFCFC",
	LIGHTISH_GREY: "#F4F4F4",
	LIGHT_TEXT_GREY: "#969696",
	MEDIUM_GREY: "#757575",
	ERROR: "#CA1435",
	ERROR_BG: "#F7DEE2",
};

export default COLORS;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import COLORS from "../../utils/colors";

const useStyles = makeStyles({
	root: {
		fontWeight: "600",
		color: COLORS.ALMOST_BLACK,
		fontSize: "24px",
		textAlign: "center",
		marginTop: "32px",
		marginBottom: "16px"
	},
});

function FormLabelBold(props) {
	const classes = useStyles();

	return (
		<FormLabel
			className={classes.root}
			{...props}
		/>
	);
}

export default FormLabelBold;

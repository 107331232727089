import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import COLORS from "../../utils/colors";

const formTheme = createTheme({
	typography: {
		fontFamily: ["Graphik Web"],
	},
	palette: {
		primary: {
			main: COLORS.IT_GREEN,
		},
		error: {
			main: COLORS.ERROR,
		},
	},
	shape: {
		borderRadius: 2,
	},
	overrides: {
		MuiFormControl: {
			root: {
				margin: "16px 0",
				width: "100%",
			},
		},
		MuiFormLabel: {
			root: {
				"&$focused": {
					color: COLORS.ALMOST_BLACK,
				},
			},
		},
		MuiTextField: {
			root: {
				margin: "16px 0",
			},
		},
		MuiFilledInput: {
			root: {
				fontSize: "20px",
				backgroundColor: COLORS.WHITE,
				"&$focused": {
					backgroundColor: COLORS.LIGHTISH_GREY,
				},
				"&:hover": {
					backgroundColor: COLORS.LIGHTISH_GREY,
				},
				"&.Mui-error": {
					backgroundColor: COLORS.ERROR_BG,
				},
				"&.Mui-error .MuiInputAdornment-root": {
					color: COLORS.ERROR,
				},
			},
		},
		MuiFormControlLabel: {
			root: {
				padding: "4px 8px",
				backgroundColor: COLORS.LIGHTISH_GREY,
				border: "2px solid transparent",
				marginLeft: "0px",
				marginRight: "0px",
				"&:focused": {
					border: `2px solid ${COLORS.IT_GREEN}`,
					boxShadow: "1px 1px 10px rgb(0 0 0 / 15%)",
				},
				"&:hover": {
					border: `2px solid ${COLORS.IT_GREEN}`,
					boxShadow: "1px 1px 10px rgb(0 0 0 / 15%)",
				},
			},
			label: {
				border: "1px solid transparent",
				fontSize: "20px",
				fontWeight: "600",
				color: COLORS.ALMOST_BLACK,
				marginLeft: "4px",
			},
		},
		MuiRadio: {
			root: {
				color: "black",
				"&$checked": {
					color: "green",
				},
			},
		},
	},
});

const Form = ({ children, ...props }) => {
	return (
		<ThemeProvider theme={formTheme}>
			<form {...props} noValidate>
				{children}
			</form>
		</ThemeProvider>
	);
};

export default Form;

import React from "react";
import HubspotForm from "react-hubspot-form";

function GeneralForm() {
	return (
		<div className="form">
			<>
				 <p className="form__description">We love chatting. Let's get your details.</p>
				<HubspotForm
					portalId="544161"
					formId="825bf48b-859d-400d-b779-0ad3ba7186a8"
					region="na1"
					css=""
					submitButtonClass="btn btn--black-green"
				/>
			</>
		</div>
	);
}

export default GeneralForm;

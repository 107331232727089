import React from "react";
import HubspotForm from "react-hubspot-form";

function PartnerForm() {
	return (
		<div className="form">
			<>
				<p className="form__description">We work with the best. If that's you, drop us a line here. We promise it’ll be a wild ride.</p>
				<HubspotForm
					portalId="544161"
					formId="b4684a55-8548-4f77-afda-7be80457c4bc"
					region="na1"
					css=""
					submitButtonClass="btn btn--black-green"
				/>
			</>
		</div>
	);
}

export default PartnerForm;

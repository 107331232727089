import React, { useState } from "react";
import Form from "../../FormElements/Form";
import FormLabelBold from "../../FormElements/FormLabelBold";
import { FormControl, RadioGroup, FormControlLabel } from "@material-ui/core";
import StyledRadio from "../../FormElements/StyledRadio";
import GeneralForm from "../GeneralForm";
import ProspectForm from "../ProspectForm";
import CareersForm from "../CareersForm";
import PartnerForm from "../PartnerForm";
import { CONTACT_US_FORMS } from "../../../utils/constants";
import "./styles.scss";

function ContactForm() {
	const [activeForm, setActiveForm] = useState("");

	const handleChange = e => setActiveForm(e.target.value);

	return (
		<div className="contact-form">
			<div className="container container--is-narrow">
				<Form>
					<FormControl component="fieldset">
						<FormLabelBold component="legend">
							What best describes what you’re looking for?
						</FormLabelBold>
						<RadioGroup
							aria-label="What best describes what you’re looking for?"
							name="what-are-you-looking-for"
							onChange={handleChange}
						>
							<div className="form__radio-group form__radio-group--two-column">
								{CONTACT_US_FORMS.map((form, index) => {
									return (
										<FormControlLabel
											key={index}
											value={form.name}
											control={<StyledRadio />}
											label={form.label}
										/>
									);
								})}
							</div>
						</RadioGroup>
					</FormControl>
				</Form>

				{activeForm === "General" && <GeneralForm />}
				{activeForm === "Prospects" && <ProspectForm />}
				{activeForm === "Partnerships" && <PartnerForm />}
				{activeForm === "Careers" && <CareersForm />}
			</div>
		</div>
	);
}

export default ContactForm;

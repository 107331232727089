// Sharpspring
export const SHARPSPRING_BASE_URI =
	"https://app-3QNLTK4FNC.marketingautomation.services/webforms/receivePostback/MzawMLEwMDc2AAA/";
export const SHARPSPRING_DOMAIN = "app-3QNLTK4FNC.marketingautomation.services";
export const SHARPSPRING_ACCOUNT = "MzawMLEwMDc2AAA";
export const SHARPSPRING_TARGET_ID = "SharpSpringForm";
export const SHARPSPRING_FORM_JS_SCRIPT =
	"https://koi-3QNLTK4FNC.marketingautomation.services/client/form.js?ver=2.0.1";

// Form Messages
export const FORM_MESSAGES = {
	REQUIRED: "This field is required",
	REQUIRED_MIN_LENGTH:
		"You haven't reached the minimum character limit for this field.",
	REQUIRED_MAX_LENGTH: "You've reached the max character limit.",
	REQUIRED_EMAIL_FORMAT: "Email entered does not match email format",
	FILE_SIZE_LIMIT_EXCEEDED: 'The file being uploaded exceeds the maximum allowed file size of',
	FILE_NOT_UPLOADED: 'File could not be uploaded. Please try again.'
};

// Forms available on contact us page
export const CONTACT_US_FORMS = [
	{
		name: "General",
		label: "I just wanna chat",
	},
	{
		name: "Prospects",
		label: "I’m looking to start a project",
	},
	{
		name: "Careers",
		label: "I’m looking for employment",
	},
	{
		name: "Partnerships",
		label: "I want to start a business partnership"
	},
];

export const APPROACH_PAGE = {
	HEADING_LINE_1: 'We start with',
	HEADING_LINE_2: 'Why.',
	WHY_HEADING_LINE_1: 'Business First.',
	WHY_HEADING_LINE_2: 'Online Second.',
	HOW_HEADING_LINE_1: 'Partnerships, Not',
	HOW_HEADING_LINE_2: 'Transactions.',
	WHAT_HEADING_LINE_1: 'Build & Grow',
	WHAT_HEADING_LINE_2: 'Ecommerce Channels.',
	DISCOVER: 'Discover',
	PLAN: 'Plan',
	EXECUTE: 'Execute'
}